import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/Navbar.css";

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Appeler la fonction pour initialiser l'état en fonction de la largeur actuelle

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      {isMobile ? (
        // Version de navigation déroulante pour les écrans mobiles
        <div className="menu-content">
          <button
            onClick={toggleMenu}
            className={`button-menu ${isMenuOpen ? "cross" : "burger"}`}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </button>
          <nav>
            <ul className={`menu ${isMenuOpen ? "open" : ""}`}>
              <li className="navbar-item">
                <Link to="/">Accueil</Link>
              </li>
              <li className="navbar-item">
                <Link to="/projets">Mes projets</Link>
              </li>
              <li className="navbar-item">
                <Link to="/cursus">Cursus Professionnel</Link>
              </li>
              <li className="navbar-item">
                <Link to="/langage">Langage de Programmation</Link>
              </li>
              <li className="navbar-item">
                <a
                  href="https://www.linkedin.com/in/damien-alary-95a919238/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-linkedin fa-xl linkedin"></i>
                </a>
              </li>
              <li className="navbar-item">
                <a
                  href="https://github.com/DALARY"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-github fa-xl github"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      ) : (
        <nav>
          <ul className="navbar-list">
            <li className="navbar-item">
              <Link to="/">Accueil</Link>
            </li>
            <li className="navbar-item">
              <Link to="/projets">Mes projets</Link>
            </li>
            <li className="navbar-item">
              <Link to="/cursus">Cursus Professionnel</Link>
            </li>
            <li className="navbar-item">
              <Link to="/langage">Langage de Programmation</Link>
            </li>
            <li className="navbar-item">
              <a
                href="https://www.linkedin.com/in/damien-alary-95a919238/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-linkedin fa-xl linkedin"></i>
              </a>
            </li>
            <li className="navbar-item">
              <a
                href="https://github.com/DALARY"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-github fa-xl github"></i>
              </a>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

export default Navbar;
