import React, { useState, useEffect } from "react";
import ProjetModal from "../elements_pages/ProjetModal";
import { initializeScrollAnimation } from "../elements_pages/ScrollPage";
import "../css/Projets.css";

// Import images dossier Aveyron
import aveyrondAccueil from "../images/Projets/aveyrond/Accueil.png";
import aveyrondArticle from "../images/Projets/aveyrond/Article.png";
import aveyrondClassement from "../images/Projets/aveyrond/Classement.png";
import aveyrondCompetition from "../images/Projets/aveyrond/Compétition.png";
import aveyrondCreation from "../images/Projets/aveyrond/Création_article.png";
import aveyrondEdition from "../images/Projets/aveyrond/Edition.png";
import aveyrondResultat from "../images/Projets/aveyrond/Résultat.png";

// Import images dossier Geometre
import geometreAccueil from "../images/Projets/geometre/Accueil.png";
import geometreContact from "../images/Projets/geometre/Contact.png";
import geometreEdition from "../images/Projets/geometre/Edition_point.png";
import geometreEntreprise from "../images/Projets/geometre/Entreprise.png";
import geometrePresentation from "../images/Projets/geometre/Presentation.png";
import geometreProjets from "../images/Projets/geometre/Projets.png";
import geometreTableau from "../images/Projets/geometre/Tableau.png";
import geometreUtilisateurs from "../images/Projets/geometre/Utilisateurs.png";
import geometreViewerPoint from "../images/Projets/geometre/Viewer_point.png";
import geometreViewer from "../images/Projets/geometre/Viewer.png";

// Import images dossier Maps
import maps1 from "../images/Projets/maps/Maps.png";
import maps2 from "../images/Projets/maps/Maps_2.png";

// Import images dossier Population
import populationContinent from "../images/Projets/population/Population_continent.png";
import populationRegion from "../images/Projets/population/Population_region.png";
import populationTotal from "../images/Projets/population/Total.png";

// Import images dossier Quizz
import quizzPlantAccueil from "../images/Projets/quizzPlant/Accueil.png";
import quizzPlantConnexion from "../images/Projets/quizzPlant/Connexion.png";
import quizzPlantQuestion from "../images/Projets/quizzPlant/Question.png";
import quizzPlantQuizz from "../images/Projets/quizzPlant/Quizz.png";
import quizzPlantResultat from "../images/Projets/quizzPlant/Resultat.png";

function Projets() {
  useEffect(() => {
    const cleanupScrollAnimation = initializeScrollAnimation();

    // Retirez l'écouteur d'événement lorsque le composant est démonté
    return cleanupScrollAnimation;
  });

  const projects = [
    {
      name: "Aveyrond",
      images: [
        aveyrondAccueil,
        aveyrondArticle,
        aveyrondCreation,
        aveyrondEdition,
        aveyrondCompetition,
        aveyrondClassement,
        aveyrondResultat,
      ],
      captions: [
        "Accueil",
        "Article",
        "Création d'un article",
        "Edition d'un article",
        "Compétition",
        "Classement",
        "Résultats des matchs/semaines",
      ],
    },
    {
      name: "Géometre",
      images: [
        geometreAccueil,
        geometrePresentation,
        geometreContact,
        geometreTableau,
        geometreEntreprise,
        geometreUtilisateurs,
        geometreProjets,
        geometreViewer,
        geometreViewerPoint,
        geometreEdition,
      ],
      captions: [
        "Accueil",
        "Présentation entreprise",
        "Contact",
        "Tableau de bord",
        "Client entreprise",
        "Autorisation utilisateurs",
        "Les Projets",
        "Visionneur 3D",
        "Visionneur 3D + Points",
        "Edition des points",
      ],
    },
    {
      name: "Map",
      images: [maps1, maps2],
      captions: ["Point cathédrale", "Point viaduc"],
    },
    {
      name: "Populations",
      images: [populationContinent, populationRegion, populationTotal],
      captions: [
        "Population par continent",
        "Population par région",
        "Population total",
      ],
    },
    {
      name: "Quizz Plants",
      images: [
        quizzPlantAccueil,
        quizzPlantConnexion,
        quizzPlantQuizz,
        quizzPlantQuestion,
        quizzPlantResultat,
      ],
      captions: [
        "Accueil",
        "Connexion",
        "Quizz normal/chronométrer",
        "Questions aléatoires",
        "Résultats",
      ],
    },
  ];

  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProjectClick = (index) => {
    setSelectedProjectIndex(index);
    setIsModalOpen(true); // Ouvre la modal
  };

  return (
    <div className="projets">
      <h2>Mes Projets</h2>
      <span>* Pour en savoir plus cliquez sur les images *</span>
      <p>
        Voici mes différents projets auxquelles j'ai participé durant mon année
        de Développeur Web et Web Mobile
      </p>
      {projects.map((project, index) => (
        <div className="projet-group scroll-item" key={index}>
          <h3 className="projet-name">{project.name}</h3>
          <div className="projet-images">
            <img
              src={project.images[0]}
              alt={`Projet ${index + 1}`}
              onClick={() => handleProjectClick(index)} // Ouvre la modal au clic sur l'image
            />
          </div>
        </div>
      ))}
      {selectedProjectIndex !== null && (
        <ProjetModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          images={projects[selectedProjectIndex].images}
          captions={projects[selectedProjectIndex].captions}
        />
      )}
    </div>
  );
}

export default Projets;
