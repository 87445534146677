import React, { useEffect } from "react";
import { initializeScrollAnimation } from "../elements_pages/ScrollPage";
import "../css/Cursus.css";

function Cursus() {
  useEffect(() => {
    const cleanupScrollAnimation = initializeScrollAnimation();

    // Retirez l'écouteur d'événement lorsque le composant est démonté
    return cleanupScrollAnimation;
  });
  return (
    <div>
      <h2>Mon Cursus Professionnel</h2>
      <p className="scroll-item">
        <b>2023-2024 Alternance Bachelor Concepteur de Systèmes d'Information - Développeur Logiciel et Web</b>
        <br/>
        <ul>
          <li>Concevoir et développer une application avec les technologies mobiles et Web</li>
          <li>Concevoir l'architecture des SI de l'entreprise</li>
          <li>Gérer un projet informatique d'entreprise</li>
          <li>Développer une application Multisupport avec les technologies du Web</li>
        </ul>
      </p>
      <p className="scroll-item">
        <b>2020-2021 Formation Développeur web et Web mobile (AFPA)</b>
        <br />A ce jour j’ai acquis les bases html{" "}
        <i className="fa-brands fa-html5 fa-lg html"></i>, css{" "}
        <i className="fa-brands fa-css3-alt fa-lg css"></i>, js{" "}
        <i className="fa-brands fa-js fa-lg js"></i> je m'en sers notamment sur
        ce site. J'ai eu une grosse partie de php{" "}
        <i className="fa-brands fa-php fa-lg php"></i> et de son framework{" "}
        <i className="fa-brands fa-symfony fa-lg symfony"></i> (Symfony). Sans
        oublier les bases de données qui sont de nos jours presque primordiales.
        Et finalement j'ai eu un début d'apprentissage dans la partie mobile
        avec React <i className="fa-brands fa-react fa-lg react"></i> et NodeJS{" "}
        <i className="fa-brands fa-node fa-lg node"></i>.<br />
      </p>
      <p className="scroll-item">
        <b>2016-2018 Niveau BTS Maintenance des Systèmes</b>
        <br />
        Initiation à la mécanique, l’électrique et au pneumatique avec une
        analyse des dangers durant une intervention.
      </p>
      <p className="scroll-item">
        <b>2013-2016 Lycée La Borde Basse / BAC STI2D</b>
        <br />
        J'ai pu expérimenter le développement réseaux et la conception
        industrielle (SolidWorks).
      </p>
      <h2 className="scroll-item">Expérience professionnelle</h2>
      <p className="scroll-item">
        <b>Mai-Avril 2021 Stage Entreprise Géomètre</b>
        <br />
        J'ai amélioré et développé une application web sur un visionneur 3D. Le
        but était de visualiser les différentes étapes du projet (chantier) qui
        était en cours. Utilisation de Symfony pour la partie back-end (gestion
        administration) et de Potree pour la partie visionneur 3D de notre
        application.
      </p>
      <p className="scroll-item">
        <b>Eté 2016-2020 Pierre Fabre–Conditionnement (Emploi d’été)</b>
        <br />
      </p>
      <p className="scroll-item">
        <b>2018 Stage SOFIC</b>
        <br />
      </p>
      <p className="scroll-item">
        <b>2017 Stage PAC</b>
        <br />
      </p>
    </div>
  );
}

export default Cursus;
